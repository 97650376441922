/* styles/tailwind.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #09192F;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2d1950;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #54D3D4;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(222, 130, 235, 0.911);
  border-radius: 12px;
}


.chip {
  @apply flex justify-center items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs text-teal-300;
}
.chiplist {
  @apply grid grid-cols-5 gap-2 justify-center items-center mt-5
}
.linkcard {
  @apply grid grid-cols-5 block py-4 px-5 bg-gray-800 shadow-md rounded-md transition duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-600 cursor-pointer
}

.skillscard {
  @apply flex flex-col items-center justify-center bg-gray-800 transition duration-100 ease-in-out transform hover:scale-105 hover:bg-gray-600 hover:text-white rounded-md py-2 px-4
}

